import React from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Container } from "react-grid-system";
import ImageTopDefault from "../components/img/FES_background.jpg";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
class UsersPublicProfile extends React.Component {
	state = {
		userPublicInfo: [],
		userNoexist: 2,
		cv_descriptionPublic: "",
		doc_urlPublic: "",
		educationPublic: "",
		idPublic: "",
		id_typePublic: "",
		lastnamePublic: "",
		namePublic: "",
		orgPublic: "",
		org_extraPublic: "",
		type_statusPublic: "",
		type_titlePublic: "",
		jobPublic: "",
		phonePublic: "",
	};

	componentDidMount() {
		window.scrollTo(0, 0);

		(async () => {
			await this.getUserbyID();
		})();
		ReactGA.pageview(window.location.pathname + window.location.search);
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.match.params !== prevProps.match.params) {
			(async () => {
				await this.getUserbyID();
			})();
		}
	}
	getUserbyID = async () => {
		const id = this.props.match.params.id;

		this.setState.islogged = 1;
		let formData = new FormData();
		formData.append("id", id);
		await axios
			.post("/api/users_get_by_id", formData)
			.then((res) => {
				console.log(res.data.records);
				this.setState({
					userNoexist: 0,
					userPublicInfo: res.data.records,
				});
				this.getAllUserInformation();
			})
			.catch((error) => {
				this.setState({
					userNoexist: 1,
				});
			});
	};
	getAllUserInformation() {
		const { userPublicInfo } = this.state;
		if (userPublicInfo.length > 0) {
			userPublicInfo.map((upi, index) => {
				this.setState({
					cv_descriptionPublic: upi.cv_description,
					doc_urlPublic: upi.doc_url,
					educationPublic: upi.education,
					idPublic: upi.idPublic,
					id_typePublic: upi.id_type,
					lastnamePublic: upi.lastname,
					namePublic: upi.name,
					orgPublic: upi.org,
					org_extraPublic: upi.org_extra,
					phonePublic: upi.phone,
					type_statusPublic: upi.type_status,
					type_titlePublic: upi.type_title,
					academic_degree: upi.academic_degree,
					jobPublic: upi.job,
					emailPublic: upi.email
				});
				return null;
			});
		}
	}
	render() {
		const { userNoexist } = this.state;
		const { educationPublic } = this.state;
		//const { idPublic } = this.state;
		//const { id_typePublic } = this.state;
		const { lastnamePublic } = this.state;
		const { namePublic } = this.state;
		const { academic_degree } = this.state;
		const { jobPublic } = this.state;
		const { org_extraPublic } = this.state;
		const { phonePublic } = this.state;
		const { emailPublic } = this.state;
		const { cv_descriptionPublic } = this.state;
		//	const { type_titlePublic } = this.state;

		const title = (
			<Container
				className="width-100 max-width-100 backgroundImage noticeTitle backgroundDark"
				style={{ background: `url('${ImageTopDefault}')` }}
			>
				<Row className="max-width-site">
					<Col sm={12}>
						<div className="centerTitleBlog">
							<h1 className="center">Perfil Público</h1>
							<h6 className="subtitle center textWhite">
								{namePublic + " " + lastnamePublic}
							</h6>
							<p className="center breadcrumbNotice"></p>
						</div>
					</Col>
				</Row>
			</Container>
		);

		const personalInfo = (
			<div>
				<p>
					Nombre:{" "}
					<span>{namePublic !== "" ? namePublic : "No especificado"}</span>
				</p>
				<p>
					Apellidos:{" "}
					<span>
						{lastnamePublic !== "" ? lastnamePublic : "No especificado"}
					</span>
				</p>
				<p>
					Email:{" "}
					<span>{emailPublic !== "" ? emailPublic : "No especificado"}</span>
				</p>
			</div>
		);
		const profesionalInfo = (
			<div>
				<p>
					Nivel de estudios:{" "}
					<span>{educationPublic ? educationPublic : "No especificado"}</span>
				</p>
				<p>
					Titulación:{" "}
					<span>{academic_degree ? academic_degree : "No especificado"}</span>
				</p>
				<p>
					Descripcion CV:{" "}
					<span>
						{cv_descriptionPublic ? <a href={cv_descriptionPublic} target="_blank">{cv_descriptionPublic}</a> : "No especificado"}
					</span>
				</p>				
				<p>
					Puesto de Trabajo:{" "}
					<span>{jobPublic ? jobPublic : "No especificado"}</span>
				</p>
				<p>
					Institución:{" "}
					<span>{org_extraPublic ? org_extraPublic : "No especificado"}</span>
				</p>
			</div>
		);
		let userProfile;
		if (+userNoexist === 0) {
			userProfile = "";
		} else {
			userProfile =
				"El usuario que buscas no existe o tiene el perfil privado.";
		}

		return (
			<section>
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row>
						<Col className="center">{userProfile}</Col>
						<Col sm={12}>
							<p>
								<Link
									to="/Miembros/miembros-individuales"
									className="goBacklink"
								>
									<FontAwesomeIcon icon={Icons.faArrowLeft} size="1x" /> Volver
									a Listado de miembros
								</Link>
							</p>
						</Col>
					</Row>
					{userNoexist === 0 ? (
						<Row>
							<Col lg={6} xs={12}>
								<h4 className="underlinetitles">
									{" "}
									<FontAwesomeIcon icon={Icons.faUser} size="1x" /> Información
									Personal
								</h4>
								{personalInfo}
							</Col>
							<Col lg={6} xs={12}>
								<h4 className="underlinetitles">
									{" "}
									<FontAwesomeIcon icon={Icons.faUserTie} size="1x" />{" "}
									Información Profesional
								</h4>
								{profesionalInfo}
							</Col>
						</Row>
					) : (
						""
					)}
				</Container>
			</section>
		);
	}
}

export default UsersPublicProfile;
